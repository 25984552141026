<template>
    <zw-sidebar @shown="shown" :title="$t('invoices.label.cancel_form_title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <zw-input-group v-model="form.reason"
                                        name="reason"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'invoices.label.cancel_form_',
            payload: {},
            callback: null,
            form: {
                reason: '',
            },
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Invoices/cancelInvoices', {
                        'ids': this.payload,
                        'reason': this.form.reason,
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>